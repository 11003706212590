<div class="login-display container-sm container-md">
    <form class="form" [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
        <mat-icon class="icon-display" svgIcon="logo"></mat-icon>
        <mat-form-field class="full-width">
            <label  for="username">User Name</label>
            <input  matInput id="username" formControlName="username">
        </mat-form-field>
        
        <mat-form-field class="full-width">
            <label for="password">Password</label>
            <input matInput type="password" id="password" formControlName="password">
            <!-- <mat-icon style="float: right;">remove_red_eye</mat-icon> -->
        </mat-form-field>
        <div *ngIf="login$ | async as login"></div>
        <input class="inputButton" type="submit" (click)="onSubmit()" value="SIGN IN" >
    </form>
</div>