import { Component, EventEmitter, Inject, Input } from '@angular/core';
import { BrowserMultiFormatReader } from '@zxing/library';
import { QRSelectSourceDevice } from 'src/models/qrcameraViewModel';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, filter, map, merge, of, pipe, shareReplay, switchMap, take, tap } from 'rxjs';
import { MachineId, MachineInfo } from '../share/models/machineInfo';
import { MachinesService } from '../share/services/MachinesServices';
import { OrderId, OrderInfo, OrderStatus } from '../share/models/ordersInfo';
import { OrdersService } from '../share/services/ordersService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
const Close_ICON =
  `
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
`;

@Component({
  selector: 'tr-qrcamera',
  templateUrl: './qrcamera.component.html',
  styleUrls: ['./qrcamera.component.scss']
})
export class QrcameraComponent {
  public reader = new BrowserMultiFormatReader();
  sourceSelect: QRSelectSourceDevice[] = [];
  progressBar: boolean = false;
  machineId$ = new BehaviorSubject<MachineId>({ id: 0 });
  orderId$ = new BehaviorSubject<OrderId>({ id: 0 });
  refreshStatus$ = new Subject<void>();
  machine$: Observable<MachineInfo> = new Observable<MachineInfo>();
  order$: Observable<OrderInfo> = new Observable<OrderInfo>();
  status$: Observable<OrderStatus> = new Observable<OrderStatus>();
  start$: Observable<string> = new Observable<string>();
  myStream: any;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialogRef<QrcameraComponent>,
    private machinesServices: MachinesService,
    private ordersServices: OrdersService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.NotFound = false;
    iconRegistry.addSvgIconLiteral('close', sanitizer.bypassSecurityTrustHtml(Close_ICON));
    //get machine
    this.machine$ = this.machineId$.pipe(
      filter(x => x.id != 0),
      switchMap(x => {
        return this.machinesServices.getMachineId(x.id).pipe(
          map(x => {
            this.data.description = x.description;
            this.data.number = x.number;
            this.progressBar = false;
            this.data.stream = this.myStream;
            this.myStream?.getTracks().forEach((x: any) => {
              x.stop();
              x.enabled = false;
            });
            this.reader.reset();
            this.dialog.close(this.data);
            return x;
          }),
          catchError(err => {
            console.error(err);
            this.progressBar = false;
            this.data.NotFound = true;
            this.myStream?.getTracks().forEach((x: any) => {
              x.stop();
              x.enabled = false;
            });
            this.reader.reset();
            this.dialog.close(this.data);
            this._snackBar.open('Machine Not Found', 'Dismiss', {
              duration: 3000
            });
            return of();
          })
        );
      }),
      shareReplay(1)
    );
    //get order
    this.order$ = this.orderId$.pipe(
      filter(x => x.id != 0),
      switchMap(x => {
        return this.ordersServices.getOrderId(x.id).pipe(
          map(x => {
            this.data.label = x.label;
            this.data.id = x.id;
            this.progressBar = false;
            this.myStream?.getTracks().forEach((x: any) => {
              x.stop();
              x.enabled = false;
            });
            this.reader.reset();
            this.dialog.close(this.data);
            return x;
          }),
          catchError(err => {
            this._snackBar.open('Order Not Found', 'Dismiss', {
              duration: 3000
            });
            this.progressBar = false;
            this.data.NotFound = true;
            this.myStream?.getTracks().forEach((x: any) => {
              x.stop();
              x.enabled = false;
            });
            this.reader.reset();
            this.dialog.close(this.data);
            return of();
          })
        );
      }),
      shareReplay(1)
    );
  }
  ngOnInit() {
    //get mediadevices
    navigator.mediaDevices.getUserMedia({ video: this.data.innerWidth > 600 ? true : {facingMode: 'environment'} }).then(stream => {
      stream.getTracks().forEach((x: any) => {
        x.stop();
        x.enabled = false;
      });
      this.myStream = stream;
    }).catch((err) => {
      console.log('getUserMediaError', err);
    });
    navigator.mediaDevices.enumerateDevices();
    this.reader.listVideoInputDevices().then((VideoInputDevices) => {
      if (VideoInputDevices[0].deviceId == null) {
        this._snackBar.open('No Rear Camera Found', 'Dismiss', { duration: 3000 });
      }
      if (VideoInputDevices.length >= 1) {
        VideoInputDevices.forEach((element) => {
          this.sourceSelect.push({ text: element.label.toString(), value: element.deviceId.toString() });
        });
      }
    });
    this.decodeOnce();
  }

  startButton() {
    this.decodeOnce();
  }
  resetButton() {
    this.myStream?.getTracks().forEach((x: any) => {
      x.stop();
      x.enabled = false;
    });
    this.reader.reset();
    this.data.result = '';
  }
  decodeOnce() {
    this.reader.decodeOnceFromVideoDevice(this.data.videoSource.toString(), "video").then((result: any) => {
      this.data.result = result.text;
      this.myStream?.getTracks().forEach((x: any) => {
        x.stop();
        x.enabled = false;
      });
      console.log('decode', result);
      if (result.toString() != '') {
        this.progressBar = true;
        switch (this.data.type) {
          case 'machine': {
            let machineId = new MachineId();
            machineId.id = result.text;
            this.machineId$.next(machineId);
            break;
          }
          case 'order': {
            let orderId = new OrderId();
            orderId.id = result.text;
            this.orderId$.next(orderId);
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err, 'constraint');
    });
  }
  onNoclick() {
    this.data.title = '';
    this.data.result = '';
    navigator.mediaDevices.getUserMedia({video: true}).then(x => {
      x.getTracks().forEach(y => {
        y.stop();
        y.enabled = false;
      })
    }).catch((err: any) => {
      console.log('err', err);
    });
    this.reader.reset();
    this.data.stream = this.myStream;
    this.dialog.close(this.data);
  }
  continue() {
    if (this.data.result != '') {
      this.progressBar = true;
    }
    switch (this.data.type) {
      case 'machine': {
        let machineId = new MachineId();
        machineId.id = this.data.result;
        this.machineId$.next(machineId);
        break;
      }
      case 'order': {
        let orderId = new OrderId();
        orderId.id = this.data.result;
        this.orderId$.next(orderId);
        break;
      }
    }
  }
  onChangeVideoSource(e: any) {
    this.data.videoSource = e.target.value;
  }
}
