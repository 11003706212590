import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, catchError, tap, throwError } from "rxjs";
import { LoginInfo } from "../models/LoginInfo";
import { MachineInfo } from "../models/machineInfo";
import { environment } from "src/environments/environment";
import { OrderStatus } from "../models/ordersInfo";

@Injectable({
    providedIn: 'root'
})
export class MachinesService {
    constructor(private _http: HttpClient) {}
    
    private _machine$ = new ReplaySubject<MachineInfo>();

    getMachineId(id: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("id", id);
        return this._http.get<MachineInfo>(`${environment.apiUrl}/api/machines/${id}`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }

    getStatus(machineId: number, orderId: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("machineId", machineId).set("orderId", orderId);
        return this._http.get<OrderStatus>(`${environment.apiUrl}/api/machines/${machineId}/orders/${orderId}/status`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }
    start(machineId: number, orderId: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("machineId", machineId).set("orderId", orderId); 
        return this._http.put(`${environment.apiUrl}/api/machines/${machineId}/orders/${orderId}/start`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }
    waiting(machineId: number, orderId: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("machineId", machineId).set("orderId", orderId); 
        return this._http.put(`${environment.apiUrl}/api/machines/${machineId}/orders/${orderId}/stop`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }
    finish(machineId: number, orderId: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("machineId", machineId).set("orderId", orderId); 
        return this._http.put(`${environment.apiUrl}/api/machines/${machineId}/orders/${orderId}/finish`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }
    errorHandler(error: HttpErrorResponse) {
        console.log('error', error);
        return throwError(() => new Error(error.message || 'server error'));
    }
}
