import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginInfo } from '../share/models/LoginInfo';
import { BehaviorSubject, Observable, switchMap, Subject, filter, combineLatest, shareReplay, firstValueFrom, merge, of, concatMap, timer, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { QrcameraComponent } from '../qrcamera/qrcamera.component';
import { QrInfo } from '../share/models/qrInfo';
import { MachinesService } from '../share/services/MachinesServices';
import { MachineInfo } from '../share/models/machineInfo';
import { OrdersService } from '../share/services/ordersService';
import { OrderInfo, OrderStatus } from '../share/models/ordersInfo';
import { FinishJobComponent } from '../finish-job/finish-job.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { CameraPermissionComponent } from '../camera-permission/camera-permission.component';

@Component({
  selector: 'tr-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  ScanMachine: boolean = true;
  ScanJob: boolean = false;
  NewJob: boolean = false;
  inProgress: boolean = false;
  waiting: boolean = false;
  finish: boolean = false;

  loginData: Observable<LoginInfo> | undefined;
  data: QrInfo = new QrInfo();
  orderStatus: OrderStatus = new OrderStatus();
  machineData: MachineInfo = new MachineInfo();
  orderData: OrderInfo = new OrderInfo();
  machineInfo$ = new BehaviorSubject<MachineInfo>(new MachineInfo());
  orderInfo$ = new BehaviorSubject<OrderInfo>(new OrderInfo());
  refreshStatus$ = new Subject<void>();

  machine$: Observable<MachineInfo> = new Observable<MachineInfo>();
  order$: Observable<OrderInfo> = new Observable<OrderInfo>();
  status$: Observable<OrderStatus> = new Observable<OrderStatus>();
  start$: Observable<string> = new Observable<string>();
  innerWidth: number = 0;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private machinesServices: MachinesService,
    private ordersServices: OrdersService,
    private _snackBar: MatSnackBar,
  ) {
    this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/logo.svg'));
    this.data.fullName = localStorage.getItem('fullName') || '';
    this.innerWidth = window.innerWidth;
    //get machine
    this.machine$ = this.machineInfo$.pipe(
      switchMap(x => {
        return of(x);
      }),
      shareReplay(1)
    );
    //get order
    this.order$ = this.orderInfo$.pipe(
      switchMap(x => {
        return of(x);
      }),
      shareReplay(1)
    );
    //get status of order
    const status$ = combineLatest([this.machine$, this.order$]).pipe(
      switchMap(([machine, order]) => this.machinesServices.getStatus(machine.number, order.id))
    );
    //define  refresh status
    const statusRefresh$ = this.refreshStatus$.pipe(switchMap(() => status$));
    //merge two, so the statys is updated when order/machine changes
    this.status$ = merge(status$, statusRefresh$).pipe(shareReplay(1));

    // Start function when machine$ and order$ are available
    combineLatest([this.machine$, this.order$])
      .pipe(
        filter(([machine, order]) => !!machine && !!order)
      )
      .subscribe(([machine, order]) => this.start(machine.number, order.id));
  }

  ngOnInit() {
    //is it camera allow?
    const permission = navigator.mediaDevices.enumerateDevices().then(x => {
      for(let i = 0; i < x.length; i++){
        if(x[i].kind == 'videoinput' && x[i].label == ""){
          this.openCameraPermission();
          return;
        }
      }
    });
  }
  SignOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires');
    this.router.navigate(['/login']);
  }

  getMachine() {
    const dialogRef = this.dialog.open(QrcameraComponent, {
      width: '50vh',
      panelClass: ['custom-modalbox', 'container-sm', 'container-md'],
      data: {
        title: 'Scan Machine',
        videoSource: this.data.videoSource,
        type: 'machine',
        innerWidth: this.innerWidth
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.data.videoSource = x.videoSource;
      if (x.result != "" && x.NotFound == false) {
        this.machineInfo$.next(x);
        this._snackBar.open('Scan Successful', 'Dismiss', { duration: 3000 });
        this.ScanJob = true;
        this.ScanMachine = false;
      }
      if(x.NotFound){
        timer(2000).pipe(map(() => {this.getMachine()})).subscribe();
      }
    });
  }
  async openCameraPermission() {
    const dialogRef = this.dialog.open(CameraPermissionComponent, {
      width: '50vh',
      panelClass: [ 'container-sm', 'container-md'],
      data: { title: 'Please Enable Camera' }
    });
    dialogRef.afterClosed().subscribe(x => {
    })
  }
  async openOrder() {
    const dialogRef = this.dialog.open(QrcameraComponent, {
      width: '50vh',
      panelClass: 'container',
      data: { title: 'Scan Job', videoSource: this.data.videoSource, type: 'order' }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.data.videoSource = x.videoSource;
      if (x.result != "" && x.NotFound == false) {
        this.orderInfo$.next(x);
        this._snackBar.open('Scan Successful', 'Dismiss', { duration: 3000 });
        this.ScanJob = true;
        this.NewJob = true;
      }
      if(x.NotFound){
        timer(2000).pipe(map(() => {this.openOrder()})).subscribe();
      }
    });
  }

  checkStatus(status: number) {
    switch (status) {
      case 0: {
        this.inProgress = false;
        this.waiting = false;
        this.finish = false;
        return 'Unknown';
      }
      case 1: {
        this.inProgress = false;
        this.waiting = true;
        this.finish = false;
        return "Waiting";
      }
      case 2: {
        this.inProgress = true;
        this.waiting = false;
        this.finish = false;
        return "In Progress";
      }
      case 3: {
        this.inProgress = false;
        this.waiting = false;
        this.finish = true;
        return "Finished";
      }
      default: {
        this.inProgress = false;
        this.waiting = false;
        this.finish = false;
        return '';
      }

    }
  }
  async newMachine() {
    this.machineInfo$.next(new MachineInfo());
    this.orderInfo$.next(new OrderInfo());
    this.ScanJob = false;
    this.ScanMachine = true;
    this.NewJob = false;
  }
  async newJob() {
    this.orderInfo$.next(new OrderInfo());
    this.NewJob = false;
  }
  async start(machineId: number, orderId: number) {
    await firstValueFrom(this.machinesServices.start(machineId, orderId).pipe(
      switchMap(x => {
        this.refreshStatus$.next();
        this._snackBar.open('Started', 'Dismiss', {
          duration: 3000
        });
        return of(true);
      })
    ));
  }
  async pause(machineId: number, orderId: number) {
    await firstValueFrom(this.machinesServices.waiting(machineId, orderId).pipe(
      switchMap(x => {
        this.refreshStatus$.next();
        this._snackBar.open('Paused Found', 'Dismiss', {
          duration: 3000
        });
        return of(true);
      })
    ));

  }
  async finished(machineId: number, orderId: number) {
    const dialogRef = this.dialog.open(FinishJobComponent, {
      width: '50vh',
      panelClass: ['container-sm', 'container-md'],
      data: { machineId: machineId, orderId: orderId, innerWidth: this.innerWidth }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.refreshStatus$.next();
      this._snackBar.open('Finished', 'Dismiss', {
        duration: 3000
      });
    });
  }
  viewJob(id: number) {
    window.open(`${environment.apiUrl}/Orders/View/` + id, "_blank");
  }

}
