import { NgModule, importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QrcameraModule } from './qrcamera/qrcamera.module';
// import {ZXingScannerModule } from '@zxing/ngx-scanner';
import { LoginModule } from './login/login.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './home/home.module';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { InterceptorModule } from './share/interceptors/interceptor.module';
import { FinishJobModule } from './finish-job/finish-job.module';
import { CameraPermissionModule } from './camera-permission/Camera-permission.module.ts';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HomeModule,
    BrowserModule,
    AppRoutingModule,
    QrcameraModule,
    FinishJobModule,
    LoginModule,
    CameraPermissionModule,
    // ZXingScannerModule,
    RouterOutlet,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    InterceptorModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
