import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-camera-permission',
  templateUrl: './camera-permission.component.html',
  styleUrls: ['./camera-permission.component.scss']
})
export class CameraPermissionComponent {
  myStream: any;
  innerWidth: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CameraPermissionComponent>,
    private _snackBar: MatSnackBar,){
      this.innerWidth = window.innerWidth;
  }
  ngOnInit(){}
  enableCamera() {
    navigator.mediaDevices.getUserMedia({ video: this.innerWidth > 600 ? true : {facingMode: 'environment'} }).then(stream => {
      stream.getTracks().forEach((x: any) => { x.stop()});
      this.data.stream = stream;
      this.dialog.close(this.data);
    }).catch((err) => {
      this._snackBar.open('Please enable camera permissions on settings', 'Dismiss', { duration: 3000 });
      console.log('err', err );
      this.dialog.close();
    });
  }
}
