import { Component, Inject } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MachinesService } from '../share/services/MachinesServices';
import { firstValueFrom } from 'rxjs';

const Close_ICON =
`
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
`;
@Component({
  selector: 'tr-finish-job',
  templateUrl: './finish-job.component.html',
  styleUrls: ['./finish-job.component.scss']
})

export class FinishJobComponent {
  innerWidth: number = 0;

  constructor(
    private MachineServices: MachinesService,
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer,
    private dialog: MatDialogRef<FinishJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    iconRegistry.addSvgIconLiteral('close', sanitizer.bypassSecurityTrustHtml(Close_ICON));
    this.innerWidth = this.data.innerWidth;
  }
  onNoclick(){
    this.dialog.close();
  }
  cancel() {
    this.dialog.close();
  }
  async ok(){
    await firstValueFrom(this.MachineServices.finish(this.data.machineId, this.data.orderId));
    this.dialog.close();
  }
}
