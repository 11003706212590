<div [ngClass]="innerWidth > 600 ? 'container-ph' : ''" class="container-sm container-md">
    <!-- Full Name -->
    <div class="row colheight">
        <div class="col">
            <mat-icon class="icon-display" svgIcon="logo"></mat-icon>
        </div>
        <div class="col">
            <h2 class=" display-name mt-4">{{data.fullName}}</h2>
        </div>
    </div>
    <!-- Scan Button -->
    <div class="row" *ngIf="ScanMachine">
        <div class="col"></div>
        <div class="col">
            <button class="scan-machine" type="button" (click)="getMachine()">SCAN MACHINE</button>
        </div>
    </div>
    <!-- <div *ngIf="!ScanMachine"> -->
    <div *ngIf="machine$ | async as machine">
        <!-- Machine Name -->
        <div class="row mb-4" *ngIf="!ScanMachine">
            <div class="col"></div>
            <div class="col">
                <h2 class="display-name">{{machine.description}}</h2>
            </div>
        </div>
        <div *ngIf="NewJob">
            <div class="row mb-4">
                <div class="col"></div>
                <div class="col" *ngIf="order$| async as order">
                    <h2 class="display-name">{{order.label}}</h2>
                </div>
            </div>
            <!-- Order Status -->
            <div class="row mb-4" *ngIf="status$ | async as status">
                <div class="col"></div>
                <div class="col">
                    <h2 class="display-name">{{checkStatus(status.status)}}</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Scan Job Button -->
    <div class="container-sm container-md">
        <div class="row" *ngIf="ScanJob && !NewJob">
            <div class="col"></div>
            <div class="col">
                <button class="scan-job mt-4" type="button" (click)="openOrder()">SCAN JOB</button>
            </div>
        </div>
    </div>
    <!-- Start-Pause-Finish Buttons -->
    <div class="container-sm container-md" *ngIf="machine$ | async as machine">
        <div class="row margin-top d-flex justify-content-between mb-1" *ngIf="order$| async as order">
            <div class="col" *ngIf="NewJob">
                <button class="start-btn " [ngClass]="inProgress ? 'start-off' : 'start-on'" type="button"
                    (click)="start(machine.number, order.id )">START</button>
            </div>
            <div class="col" *ngIf="NewJob">
                <button class="pause-btn" [ngClass]="waiting ? 'pause-off' : 'pause-on'" type="button"
                    (click)="pause(machine.number, order.id)">PAUSE</button>
            </div>
            <div class="col" *ngIf="NewJob">
                <button class="finished-btn" [ngClass]="finish ? 'finished-off' : 'finished-on'" type="button"
                    (click)="finished(machine.number, order.id)">FINISHED</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div [ngClass]="innerWidth > 600 ? 'container-ph' : ''" class="container-sm container-md middle-div" *ngIf="order$ | async as order">
            <div class="row" *ngIf="NewJob">
                <!-- View Job -->
                <div class="col-12 mb-1">
                    <button class="viewJob-btn" type="button" (click)="viewJob(order.id)">VIEW
                        JOB</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="innerWidth > 600 ? 'container-ph' : ''" class="container-sm container-md end-div">
                <div class="row justify-content-between mt-2">
                    <!-- Reset -->
                    <div class="col mb-3" *ngIf="NewJob">
                        <button class="newJob-btn" type="button" (click)="newJob()">NEW JOB</button>
                    </div>
                    <div class="col mb-3" *ngIf="!ScanMachine">
                        <button class=" new-machine" type="button" (click)="newMachine()"> NEW MACHINE</button>
                    </div>
                    <div class="col mb-3 ">
                        <button class="signout" (click)="SignOut()" type="button">SIGN OUT</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
