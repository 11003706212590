import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../share/services/LoginService';
import { LoginInfo } from '../share/models/LoginInfo';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, map, of, shareReplay, switchMap } from 'rxjs';
import { Route, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'tr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  LoginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    grant_type: new FormControl('password')
  });
  // login: LoginInfo | undefined;

  login$ = new Observable<LoginInfo>();
  LoginData$ = new BehaviorSubject<any>({});
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loginservice: LoginService,
    private _snackBar: MatSnackBar,
    private router: Router
    ) { 
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/logo.svg")
    );
    this.login$ = this.LoginData$.pipe(
      filter(data => data.username != null || data.password != null),
      switchMap(data => {
        return this.loginservice.Login({username: data.username, password: data.password}).pipe(
          map(x => {
            localStorage.setItem( 'access_token', x.access_token);
            localStorage.setItem('expires', x['.expires']);
            localStorage.setItem('fullName', x['fullName']);
            this.router.navigate(['/home']);
            return x;
          }),
          catchError(err => {
            console.log(err);
            this._snackBar.open('Login failed. Make sure username and password are correct.', 'Dismiss', {
              duration: 3000
            });
            return of();
          })
        );
      }),
      shareReplay(1),
      
    );
  }
  
  
  onSubmit(){
    if(this.LoginForm.valid){
      this.LoginData$.next({
        username: this.LoginForm.value.username?.toString(),
        password: this.LoginForm.value.password?.toString() 
      });
    }
  }
}
