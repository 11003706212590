import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { LoginInfo } from "../models/LoginInfo";
import { environment } from "src/environments/environment";
import { OrderInfo } from "../models/ordersInfo";

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    constructor(private _http: HttpClient) {}
    
    getOrderId(id: number) {
        var headers = new HttpHeaders({'Content-Type': 'application/json, text/plain, */*'});
        let params = new HttpParams().set("id", id);
        return this._http.get<OrderInfo>(`${environment.apiUrl}/api/orders/${id}`, {headers: headers, params: params}).pipe(catchError(this.errorHandler));
    }

    errorHandler(error: HttpErrorResponse) {
        console.log('error', error);
        return throwError(() => new Error(error.message || 'server error'));
    }
}
