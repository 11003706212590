import { formatDate } from "@angular/common";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(private router: Router){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let access_token = localStorage.getItem('access_token');
        let expirestr = localStorage.getItem('expires') || '';
        let expires = new Date(expirestr.toString());
        let today = new Date();
        if ((access_token != null) && (today < expires)) {
            const authReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer` + ` ${access_token}`)
            });

            return next.handle(authReq);
        }
        this.router.navigate(['/login']);
        return next.handle(req);
    }
}