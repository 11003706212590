import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { LoginInfo } from "../models/LoginInfo";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private _http: HttpClient) {}
    
    createHeader(headers: Headers){
        headers.append('Authorization', 'Bearer' + btoa('username' + 'password'));
    }
    Login(dataForm: any) {
        var headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this._http.post<any>(`${environment.apiUrl}/token`,'grant_type=password&username='+ dataForm.username + '&password=' +dataForm.password, {headers: headers} ).pipe(catchError(this.errorHandler));
    }
    
    errorHandler(error: HttpErrorResponse) {
        console.log('error', error);
        return throwError(() => new Error(error.message || 'server error'));
    }
}
