import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { QrcameraModule } from '../qrcamera/qrcamera.module';
import {MatDialogModule} from '@angular/material/dialog';
import { FinishJobComponent } from './finish-job.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    QrcameraModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule
    ],
  exports: [FinishJobComponent],
  declarations: [FinishJobComponent],
  providers: []
})
export class FinishJobModule { }
