import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { QrcameraModule } from '../qrcamera/qrcamera.module';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    HomeRoutingModule,
    MatIconModule,
    QrcameraModule,
    MatDialogModule
    ],
  exports: [HomeComponent],
  declarations: [HomeComponent],
  providers: []
})
export class HomeModule { }
