import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QrcameraComponent } from './qrcamera.component';
import { BrowserModule } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
const routes: Routes = [];

@NgModule({
  imports: [
    BrowserModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes),
    ],
  exports: [QrcameraComponent],
  declarations: [QrcameraComponent],
  providers: []
})
export class QrcameraModule { }
