export class OrderInfo {
    id: number = 0;
    orderNumber: number = 0;
    label: string = '';
    bookTitle: string = '';
}

export class OrderStatus {
    status: number = 0;
}

export class OrderId {
    id: number =0;
}