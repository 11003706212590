import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isStandalone } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'BinderyApp';
  
  constructor() {
  }

  ngOnInit() {
  }
}
