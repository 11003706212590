<div class="overflow-modalbox text-center mt-4 mb-4">
  <div style="margin-bottom: 25px;">
    <h1>{{data.title}}</h1>
    <a><button class="scanning_button" id="startButton" (click)="startButton()">Start</button></a>
    <a><button class="scanning_button" id="resetButton" (click)="resetButton()">Reset</button></a>
  </div>
  <div *ngIf="machine$ | async"></div>
  <div *ngIf="order$ | async"></div>
  <div class="container-sm container-md">
    <video id="video" class="videoWidth-1" style="border: 1px solid gray"></video>
  </div>
  <button class="btn" mat-button (click)="onNoclick()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="container-sm container-md">
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <mat-form-field>
          <select class="text-center" matNativeControl (change)="onChangeVideoSource($event)"
            [(ngModel)]="data.videoSource">
            <option value="">Select a video source.</option>
            <option *ngFor="let option of sourceSelect" [value]="option.value">
              {{option.text}}
            </option>
          </select>
        </mat-form-field>
      </div>
      <div class="col"></div>
    </div>

  </div>
  <label>Result:</label>
  <a><button class="btn-result mb-3" *ngIf="data.result" (click)="data.result=''">Clear Result</button></a>
  <div class="container-sm container-md">
    <div class="col-12 mb-3">
      <input [(ngModel)]="data.result" />
    </div>
    <div class="col-12">
      <div [ngClass]="progressBar ? 'hidden' : 'visible'">
        <button class="scanning_button" id="continue" (click)="continue()">Continue</button>
      </div>
    </div>
    <div [ngClass]="!progressBar ? 'hidden' : 'visible'" class="col-12">
      <mat-spinner [color]="'accent'" [mode]="'indeterminate'" [diameter]="25"></mat-spinner>
    </div>
  </div>