<div class="container-sm container-md mt-2 ml-2 mb-2">
  <div class="row">
    <div class="col-1 red-dot"></div>
    <div class="col-10">
      <h3>Finish Job</h3>
    </div>
    <div class="col-1">
      <button class="close-btn position-absolute end-0 top-0" mat-button (click)="onNoclick()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
    <div class="col-12 col-md-12">Do you really want to finish the current job?</div>
    <div class="d-flex justify-content-end">
      <button (click)="cancel()" class="cancel-btn btn-btn">Cancel</button>
      <button (click)="ok()" class="ok-btn btn-btn ms-2">OK</button>
    </div>
  </div>
</div>