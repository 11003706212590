export class MachineInfo {
    number: number = 0;
    description: string = '';
    function : string = '';
    departmentId: number = 0;
    title: string = '';
    result: string = '';
    videoSource: string = '';
    err: string = '';
    type: string = '';
}

export class MachineId {
    id: number = 0;
}