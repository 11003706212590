import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { QrcameraModule } from '../qrcamera/qrcamera.module';
import {MatDialogModule} from '@angular/material/dialog';
import { CameraPermissionComponent } from './camera-permission.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    QrcameraModule,
    MatDialogModule
    ],
  exports: [CameraPermissionComponent],
  declarations: [CameraPermissionComponent],
  providers: []
})
export class CameraPermissionModule { }
